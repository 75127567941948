import React, { useRef } from "react"

const QuestionItem = ({ faq, active, onToggle }) => {
    const { question, answer } = faq;
    const contentEl = useRef();

    return (
        <li className={`question_item ${active ? "active" : ""}`}>
            <button className="button" onClick={onToggle}>
                {question}
                <span className="control">{active ? "-" : "+"} </span>
            </button>
            <div 
                ref={contentEl} 
                className="answer_wrapper"
                style={
                    active
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }
                }
            >
                <div className="answer">{answer}</div>
            </div>
        </li>
    );
};
  
export default QuestionItem;