import React, { useState } from "react"
import psicopedVideo from "../videos/psicopedgiseliabila-briefing.mp4"
import data from "../data"
import QuestionItem from "./faq/QuestionItem";
import SocialMedia from "./SocialMedia"

const Header = () => {
  const posterImage = "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/v1657766654/psicopedgiseliabila/psicopedgiseli_abila_video_thumbnail_du7eso.jpg"
  const treatmentImageSrc = "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/c_thumb,g_face,h_200,q_100,r_max,w_200/v1657587223/psicopedgiseliabila/service-one-MINIFIED_viitjn.webp"

  const fileSources = [
    {imgSrc: "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/c_fill,h_400,w_600/v1679419331/dramonique/work_1_ecv2lv.webp", imgAlt: data.workOneImageAlt},
    {imgSrc: "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/c_fill,h_400,w_600/v1679419331/dramonique/work_2_wpazol.webp", imgAlt: data.workTwoImageAlt},
    {imgSrc: "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/c_fill,h_400,w_600/v1679419331/dramonique/work_3_tyscla.webp", imgAlt: data.workThreeImageAlt},
    {imgSrc: "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/c_fill,h_400,w_600/v1679419331/dramonique/work_4_w2jjnx.webp", imgAlt: data.workFourImageAlt},
  ]
  const imageWhoAmISrc = "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/c_thumb,g_face,h_278,q_100,r_max,w_278/v1679418773/dramonique/perfil_monique_dk5red.webp"
  const [clicked, setClicked] = useState("0");
  const handleToggle = (index) => {
      if (clicked === index) {
          return setClicked("0");
      }
      setClicked(index);
  };


  return (
    <div className="section">
      <div class="custom-shape-divider-top-1667181236">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
        </svg>
      </div>

      {/* 1 */}
      {/* O suporte que o seu filho precisa para desenvolver autonomia e sucesso na vida escolar.  */}
      {/* <div className="main-container zero-container">  
        <div className="section-content">
          <h1 className="main-color-second">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget do</h1>
          <h2 className="h2-default main-color-second-darker">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nas</h2>
          <p className="mt-3"><strong className="main-color-second-darker">Consulta 100% personalizada</strong></p>
        </div>
        <div>
          <div className="video-content">
            <video 
              controls 
              disablepictureinpicture
              disableremoteplayback Experimental
              poster={posterImage}
              height="100%" 
              width="100%">
                <source src={psicopedVideo} type="video/mp4" />
                <track kind="captions"/>
                Nos desculpe, seu navegador não suporta o vídeo.
            </video>
          </div>
          <div className="buttons-column">
            <a className="first-pattern" target="_blank" href={data.whatsappLink} >Agendar minha consulta</a>
          </div>
        </div>
      </div> */}

      {/* 2 */}
      {/* <div className="container-colored pt-3 pb-3">
        <div className="main-container first-container">
          <div className="section-content">
            <h1 className="main-color-second">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aene</h1>
            <p className="main-color-second-darker">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus</p>
            <h2 className="h2-default main-color-second-darker">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo lig</h2>
            <p className="main-color-second-darker">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et mag</p>
          </div>
        </div>
      </div> */}

      {/* 3 */}
      {/* <div className="pt-3 pb-3">
        <div className="main-container second-container">
          <div className="section-content">
            <div className="image-wrapper">
              <img className="round-img lazyload" data-src={treatmentImageSrc} alt={data.serviceOneImageAlt} />
            </div>
            <div className="text-wrapper">
              <h1>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean</h1>
              <ul className="list-column">
                <li>A <strong>leitura</strong>, <strong>escrita</strong> e solução de <strong>problemas matemáticos</strong></li>
                <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula</li>
                <li>Lorem ipsum dolor sit amet, consectetuer adi</li>
                <li>Lorem ipsum dolor sit amet, consectetuer adipiscing</li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}

      {/* 4 */}
      {/* Serviços */}
      <div className="container-colored-green pt-3 pb-3">
        <div className="main-container third-container">
          <div className="content">
            <h1 className="main-color-third">Serviços</h1>
            {/* <h2 className="h2-default main-color-third-darker">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus..</h2> */}
            <p className="main-color-third-darker">Os serviços são: </p>
            <div className="services-list">
              <div className="list-group">
                <div className="list-item">
                  <h3 className="main-color-third-darker">+ TOXINA BOTULÍNICA</h3>
                </div>
                <div className="list-item">
                  <h3 className="main-color-third-darker">+ SECAGEM DE MICROVASOS (PEIM)</h3>
                </div>
              </div>
              <div className="list-group">
                <div className="list-item">
                  <h3 className="main-color-third-darker">+ HARMONIZAÇÃO FACIAL</h3>
                </div>
                <div className="list-item">
                  <h3 className="main-color-third-darker">+ LIPOENZIMÁTICA (enzimas para gordura)</h3>
                </div>
              </div>
              <div className="list-group">
                <div className="list-item">
                  <h3 className="main-color-third-darker">+ PREENCHIMENTO FACIAL (COM ÁCIDO HIALURÔNICO)</h3>
                </div>
                <div className="list-item">
                  <h3 className="main-color-third-darker">+ INTRADERMO TERAPIA PARA FLACIDEZ</h3>
                </div>
              </div>
              <div className="list-group">
                <div className="list-item">
                  <h3 className="main-color-third-darker">+ LIPO DE PAPADA (NÃO ASPIRATIVA)</h3>
                </div>
                <div className="list-item">
                  <h3 className="main-color-third-darker">+ BOOSTER INJETÁVEL ("SKINBOOSTER")</h3>
                </div>
              </div>
              <div className="list-group">
                <div className="list-item">
                  <h3 className="main-color-third-darker">+ BIOESTIMULADORES DE COLÁGENO</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="buttons-column mt-5">
            <a className="second-pattern" target="_blank" href={data.whatsappLink}>Quero agendar uma consulta</a>
          </div>
        </div>
      </div>
      
      {/* 5 */}
      {/* <div className="pt-3">
        <div className="main-container fourth-container">
          <div className="section-content">
            <h1>Lorem ipsum dolor sit amet, consectetuer adipiscing eli</h1>
            <h2 className="h2-default">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis na</h2>
            <ul className="list-column">
              <li>Lorem ipsum dolor sit amet, consectetuer adip</li>
              <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean.</li>
              <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula ege</li>
              <li>Lorem ipsum d</li>
              <li>Lorem ipsum dolor sit amet, consectetuer adip</li>
            </ul>
          </div>
        </div>
      </div> */}
      
      {/* Fotos Espaço de Atendimento */}
      <div className="main-container fifth-container">
        <div className="grid">
          {fileSources.map((imageObject, index) => (
            <img className="lazyload"
              data-src={imageObject.imgSrc} 
              alt={imageObject.imgAlt} 
              key={index} />
          ))}
        </div>
      </div>

      {/* 6 */}
      {/* <div className="container-colored-red pt-3 pb-3">
        <div className="main-container sixth-container">
          <div className="content">
            <h1>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean ?</h1>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetu</p>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aen</p>
          </div>
        </div>
      </div> */}

      {/* 7 */}
      <div className="main-container nineth-container">
        {/* <div className="section-content">
          <h1>Veja o que os clientes estão dizendo: </h1>
          <div className="grid-list">
            <div className="post">
              <p>“O trabalho que a Giseli faz com meu filho de 16 anos é um divisor em sua vida escolar. A avaliação do caso, o direcionamento e os treinamentos que ele recebe, o contato com a escola e a orientação a nós familiares está sendo fundamental para a melhoria contínua do desempenho escolar, além do resgate de sua autoestima e da perspectiva do seu futuro acadêmico.”</p>
              <p className="stars">★★★★★ (5)</p>
              <span className="author">Sheyla Frioli Gottardo</span>
            </div>
            <div className="post">
              <p>“A profissional é maravilhosa, meu filho se prepara para ir no horário do atendimento. A evolução dele está nos surpreendendo. Ambiente, limpo e lindo, totalmente preparado para as crianças.  Parabéns!!!”</p>
              <p className="stars">★★★★★ (5)</p>
              <span className="author">Eliana Guerberoff</span>
            </div>
            <div className="post">
              <p>“Estou amando as sessões que estamos fazendo com minha filha, ela é autista tem 4 anos teve vários atrasos e a Gi está nos ajudando muito, a layan (minha filha) está evoluindo, sou muito grata a você Gi.”</p>
              <p className="stars">★★★★★ (5)</p>
              <span className="author">Sara Abo Nassif</span>
            </div>
          </div>
        </div> */}
        <div className="buttons-column mt-5">
          <a className="second-pattern" target="_blank" href={data.whatsappLink}>Agendar consulta</a>
        </div>
      </div>

      {/* 8 */}
      <div className="container-colored-second pt-3 pb-3">
        <div className="tenth-container main-container">
          <div className="section-content">
            <div className="image-wrapper">
              <img className="round-img lazyload" data-src={imageWhoAmISrc} alt={data.whoWeAreImageAlt} />
            </div>
            <div className="text-wrapper">
              <h1>Quem sou</h1>
              <p>Olá, Eu sou Dra Monique Lopes Abila, biomédica esteta, proprietária do consultório Dra Monique Lopes Abila, em São Paulo.</p>
              <p>Sou especialista em procedimentos estéticos injetáveis, faciais e corporais.</p>
              <p>- Graduada em Biomedicina</p>
              <p>- Biomédica esteta (CRBM1 43.750) através do estágio supervisionado</p>
              <p>- Proprietária e biomédica esteta no consultório Dra Monique Lopes Abila</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="pt-3 pb-3">
        <div className="thirteenth-container main-container">
          <div className="section-content">
            <h2 className="main-color-third">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. <strong className="main-color-third">Aenean commodo ligula</strong> eget dolor. Aenean massa. Cum sociis natoque</h2>
          </div>
        </div>
      </div> */}

      {/* 9 */}
      {/* FAQ: */}
      <div className="container-colored pt-3 pb-3">
        <div className="eleventh-container main-container">
          <div className="content">
            <h1 className="main-color-second">FAQ - DÚVIDAS FREQUENTES</h1>
            <div className="accordion-wrapper">
              <ul>
                {data.faqs.map((faq, index) => (
                    <QuestionItem 
                        key={index} 
                        faq={faq} 
                        onToggle={() => handleToggle(index)}
                        active={clicked === index}/>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Footer: */}
      {/* Lista */}
      <div className="pb-3">
        <div className="twelveth-container main-container">
          <div className="content">
            <SocialMedia/>
            <p><strong>© 2023 Clínica Estética</strong> - Dra. Monique Abila Lopes -  Todos os direitos reservados.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
